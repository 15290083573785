import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ConnectButton from '../components/connect-button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { T, P } from '../components/Typography';

const D = () => <Divider sx={{ m: 4, mb: 4 }} />;

function NFT( ) {
  const navigate = useNavigate();

  const goto2022 = useCallback(() => navigate('/NFT/2022'), []);
  const goto2023 = useCallback(() => navigate('/NFT/2023'), []);

  return <Box>
    <T>Starting in 2022, we will be releasing a <P>limited edition fractional NFT</P> to celebrate Algorand MainNet Block-Zero anniversaries.</T>

    <D />

    <Typography align="center" variant="h2" sx={{ mb: 3 }}>2023 Algorand Birthday NFT</Typography>
    <T>In 2023, we will release <P>208 copies </P>of the NFT, one for each MainNet week of operation since June 11, 2019.</T>
    <T>The NFT is minted using the celebratory address <P>JUNEII..CAME</P> just to make it a bit more special.</T>
    <T>The NFT costs <P>40Ⱥ</P>, but clockwatchers who joined us for midnight UTC can got one for free.</T>
    <T>You can also get a significant discount if you choose to pay with $COOP.</T>
    <T>The NFT is only available on the day - <P>June 11</P> - by UTC timezone.</T>
    <T>You can only purchase <P>one copy </P>of the NFT per account.</T>
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, justifyContent: 'space-around', alignItems: 'center', pl: 10, pr: 10, mt: 4, mb: 5 }}><Button variant="contained" onClick={goto2023}>View 2023 NFT</Button> </Box>

    <D />

    <Typography align="center" variant="h2" sx={{ mb: 3 }}>2022 Algorand Birthday NFT</Typography>
    <T>In 2022, we released <P>1096 copies </P>of the NFT, one for each MainNet day of operation since June 11, 2019.</T>
    <T>The NFT is minted using the celebratory address <P>JUNEII..CAME</P> just to make it a bit more special.</T>
    <T>The NFT cost <P>3Ⱥ</P>, but clockwatchers who joined us for midnight UTC can got one for free.</T>
    <T>The NFT is only available on the day - <P>June 11</P> - by UTC timezone.</T>
    <T>You can only purchase <P>one copy </P>of the NFT per account.</T>
    { /* <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, justifyContent: 'space-around', alignItems: 'center', minHeight: '110px', pl: 10, pr: 10, mt: { xs: 3, md: 0} }}><Button variant="contained">Check Availability</Button> <Button variant="outlined">Check Discount Eligibiligy</Button> </Box> */ }
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column-reverse', md: 'row' }, justifyContent: 'space-around', alignItems: 'center', pl: 10, pr: 10, mt: 4, mb: 5 }}><Button variant="contained" onClick={goto2022}>View 2022 NFT</Button> </Box>


  </Box>;
}

export default NFT;
