import { useMemo } from 'react';
import FlashOne from './FlashOne';

export default function({config: initConfig, lastRef, onStart, onLastShow, onLastDone}) {

  const config = useMemo(() => {
    let delay = initConfig.delay;
    const step = initConfig.stepDuration;
    const last = initConfig.values.length - 1;
    return initConfig.values.map((value, i) => {
      // console.log(value, i, step);
      let dstep = value === '' ? 2 * step : step;
      if (i === last) {
        dstep *= initConfig.lastMultiply ?? 5;
      } else if (i === 0) {
        dstep *= initConfig.firstMultiply ?? 1;
      }
      const elem = <FlashOne onShow={i === last ? onLastShow : i === 0 ? onStart : null } lastRef={i === last ? lastRef : null} onDone={i === last ? onLastDone : null } key={`flash-${i}`} value={value} delay={delay} duration={dstep} />
      delay += dstep + 1;
      return elem;
    });
  }, [initConfig]);

  return <>{config}</>

}
