import Box from '@mui/material/Box';

export function HFlex({children, hide, ...props}) {
  const { sx, ...restProps } = props;
  return <Box {...restProps} sx={{display: hide ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center', ...sx}}>{children}</Box>
}

export function VFlex({children, ...props}) {
  const { sx, ...restProps } = props;
  return <HFlex {...restProps} sx={{flexDirection: 'column', ...sx}}>{children}</HFlex>
}

