import { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import ConnectButton from '../components/connect-button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import A from '@mui/material/Link';
import NFTVideo from '../components/nft2022';
import { T, P } from '../components/Typography';
import theme from '../theme';
import api from '../api';
import { VFlex, HFlex } from '../components/layout';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { countdown } from '../state/index';

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const D = () => <Divider sx={{ m: 4, mb: 4 }} />;

const Loading = () => <Box sx={{width: 100}}><LinearProgress /></Box>;

function NFT({ account, discountToken, NFT }) {

  const location = useLocation();
  const navigate = useNavigate();

  const [txId, setTxId] = useState();
  const [showTxId, setShowTxId] = useState();

  useEffect(() => setShowTxId(txId ?? <Loading />), [txId]);

  useEffect(() => {
    if (location.hash) {
      const tx = location.hash.slice(1);
      if (/^[0-9A-Z]{52}$/.test(tx))
        setTxId(tx);
      else {
        const decoded = decodeURIComponent(tx);
        if (/^[A-Za-z0-9+\/=]{32,58}$/.test(decoded))
          setTxId(decoded);
      }
    }
  }, [location]);

  const viewInExplorer = useCallback(() => {
    const encTxId = encodeURIComponent(txId);
    const url = `https://allo.info/tx/group/${encTxId}`;
    if (!window.open(url))
      location.href = url;
  }, [txId]);


  const viewInNFTExplorer = useCallback(() => {
    const url = `https://explorer.perawallet.app/asset/${NFT.id}`;
    if (!window.open(url))
      location.href = url;
  }, [NFT.id]);

  const tweet = useCallback(() => {
    const url = `https://twitter.com/intent/tweet?text=3%20years%20of%20%23Algorand%20MainNet%20since%20June%2011%2C%202019!%0A%0AI%20got%20%40d13_co's%20animated%20birthday%20%23NFT%20from%20https%3A%2F%2FIsItAlgorandsBirthday.com%0A%0Ahttps%3A%2F%2Fexplorer.perawallet.app%2Fasset%2F${NFT.id}%0A%0AHappy%20Birthday%20%24ALGO!`;
    if (!window.open(url))
      location.href = url;
  }, [NFT.id]);

  return <Box>
    <Typography align="center" variant="h1" color="primary" sx={{ mb: 3 }}>Enjoy your NFT!</Typography>
    <Box sx={{ display: 'flex', mb: 2, flexDirection: 'row', justifyContent: 'center' }}>
      <NFTVideo />
    </Box>
    <HFlex sx={{ mt: 2 }}><Box>Success!</Box></HFlex>
    <HFlex sx={{ mt: 2 }}><Box>Transaction ID:</Box></HFlex>
    <HFlex><Typography>{showTxId}</Typography></HFlex>
    <D />
    <HFlex sx={{ mt: 0 }}><Button variant="outlined" disabled={!!!txId} onClick={tweet}>Tweet about it</Button></HFlex>
    <HFlex sx={{ mt: 2 }}><Button variant="outlined" disabled={!!!txId} onClick={viewInNFTExplorer}>View NFT on Pera Explorer</Button></HFlex>
    <HFlex sx={{ mt: 2 }}><Button variant="outlined" disabled={!!!txId} onClick={viewInExplorer}>View transaction on Allo</Button></HFlex>
    <D />
    <HFlex>
      <ConnectButton account={account} onDisconnect={api.disconnectWallet}/>
    </HFlex>
  </Box>;
}

export default observer(NFT);
