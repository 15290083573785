import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import { lightGreen } from '@mui/material/colors'
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

function Alt({children}) {
  return <>{children}</>;
  // return <Typography component="span" sx={{fontWeight: 'normal'}} variant="inherit" color="primary">{children}</Typography>
}

function Header({ dimStep }) {

  const classes = useMemo(() => {
    return dimStep.step <= dimStep.breaks.header ? { root: 'fg-fade' } : {};
  }, [dimStep.step > dimStep.breaks.header]);

  const title = useMemo(() => dimStep.birthday ? `It Is Algorand's Birthday!` :  `Is It Algorand's Birthday?`, [dimStep.birthday]);

  return <AppBar position="static" classes={classes} sx={{ background: 'black', padding: 3 }}>
    <Typography variant="h1" color="primary" align="center">
      {title}
    </Typography>
  </AppBar>
}

export default observer(Header);
