import moment from 'moment';
import { makeAutoObservable } from "mobx"
import ordinal from '../utils/ordinal';

const bday = '06-11';
const bdayAfter = '06-12';

class Countdown {
  seconds = null;
  minutes = null;
  hours = null;
  days = null;
  months = null;
  diff = Math.infinity;
  proper = false;
  nth = false;
  ordinal = false;
  serverNow = null;

  constructor() {
    makeAutoObservable(this);
    const now = moment.utc();
    const year = now.year();
    const afterBday = moment.utc(`${year}-${bdayAfter}`);
    if (now.isAfter(afterBday))
      this.setFrom(moment.utc(`${year + 1}-${bday}`));
    else
      this.setFrom(moment.utc(`${year}-${bday}`));
  }

  disableSetFrom(val) {
    this.setFromDisabled = val;
  }

  setProper(val) {
    this.proper = val;
  }

  setFrom(from) {
    if (this.setFromDisabled)
      return console.log('setFrom disabled');
    if (!moment.isMoment(from)) {
      from = moment.unix(from);
    }
    this.from = from;
    this.nth = from.year() - 2019;
    this.ordinal = ordinal(this.nth);
    this.calculate();
    if (!this.interval)
      this.start();
  }

  setServerDiff(val) {
    this.proper = true;
    this.serverDiff = val;
  }

  start() {
    this.interval = setInterval(() => this.calculate(), 1000);
  }

  stop() {
    clearInterval(this.interval);
  }

  calculate() {
    const now = moment().add(this.serverDiff, 'seconds');
    this.serverNow = now.unix();
    this.diff = this.from.diff(now);
    const duration = moment.duration(this.diff);
    this.seconds = duration.seconds();
    this.minutes = duration.minutes();
    this.hours = duration.hours();
    this.days = duration.days();
    this.months = duration.months();
  }
}

export default Countdown;
