import useTimeout from '../hooks/use-timeout';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

export default function({value, lastRef, delay, duration, onShow, onDone}) {
  const [show, setShow] = useState();

  useTimeout(() => {
    setShow(value);
    if (onShow)
      onShow();
  }, delay);
  useTimeout(() => {
    setShow();
    if (onDone)
      onDone();
  }, delay+duration);

  return show ? <Typography ref={lastRef} classes={{root: 'huge'}} variant="h1">{show}</Typography> : null;

}
