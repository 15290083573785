import { useCallback } from 'react';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { common } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';

function Footer({ dimStep }) {
  const openD13 = useCallback(() => {
    window.open('https://d13.co/isitalgorandsbirthday/');
  });

  return <Paper sx={{ background: 'black', overflow: 'hidden' }} classes={{ root: dimStep.step > dimStep.breaks.footer ? '' : 'fg-fade' }}>
    <Box sx={{opacity: 0.3, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={openD13}>
      <img src="/D13.png" className="hover-me" style={{ margin: 4 }} />
    </Box>
  </Paper>
}

export default observer(Footer);
