export default function ordinal(number) {
  switch (number) {
    case 1:
    case 21:
      return number + 'st';
      break;
    case 2:
    case 22:
      return number + 'nd';
      break;
    case 3:
    case 23:
      return number + 'rd';
      break;
    default:
      return number + 'th';
  }
}
