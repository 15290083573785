import { v4 } from 'uuid';
import { makeAutoObservable, reaction } from "mobx"
import { load, save } from './persist';

const _type = 'discountToken';

class DiscountToken {
  token = null

  constructor(newObj) {
    makeAutoObservable(this);

    load(_type, (val) => this.setToken(val));

    reaction(() => this.token, (newValue, prevValue) => {
      console.log('saving token', this.token);
      save(_type, this.token);
    });
  }

  setToken(token) {
      console.log('setting token', this.token);
    this.token = token;
  }
}

export default DiscountToken;
