import { useState, useCallback } from 'react';
import Box from '@mui/material/Box';
import { SpinnerCircular } from 'spinners-react';

const Loader = () => <SpinnerCircular size={59} thickness={180} speed={180} color="rgba(79, 172, 57, 1)" secondaryColor="rgba(0, 0, 0, 0)" />

const NFTVideo = ({width = 600, height = 600, ...rest}) => {
  const [loaded, setLoaded] = useState(false);

  const onLoad = useCallback(() => {
    setLoaded(true)
  }, []);

  return <>{!loaded ? <Box sx={{ width, height, display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Loader /></Box> : null}<video style={{display: loaded ? 'block' : 'none' }} onCanPlay={onLoad} {...rest} width={width} height={height} autoPlay={true} muted={true} loop={true} playsInline={true}>
    <source src="/nft2024.mp4" type="video/mp4"/>
  </video></>
}

const Container = () => <>
  <Box classes={{ root: '' }} sx={{ display: { xs: 'block', sm: 'none', }, border: '2px #76f935 solid', backgroundColor: '#051b04' }}>
    <NFTVideo width={300} height={300} />
  </Box>
  <Box classes={{ root: '' }} sx={{ display: { xs: 'none', sm: 'block', }, border: '2px #76f935 solid', backgroundColor: '#051b04' }}>
    <NFTVideo width={450} height={450} sx={{ display: { sm: 'block', md: 'none' }}} />
  </Box>
</>;

export default Container;
