export function load(type, saveFn) {
  const encoded = localStorage.getItem(type);

  if (!encoded)
    return;

  try {
    saveFn(JSON.parse(encoded));
  } catch(e) {
    console.log(`While loading localStorage.${type}: ${e.message}`);
  }
}

export function save(type, val) {
  console.log('saving', type, val);
  try {
    localStorage.setItem(type, JSON.stringify(val));
  } catch(e) {
    console.error(`While saving localStorage.${type}: ${e.message}`);
    console.error('Value was:');
    console.error(val);
  }
}
