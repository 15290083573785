import { v4 } from 'uuid';
import { makeAutoObservable } from "mobx"

class NFT {
  id = 773272335;

  constructor() {
    makeAutoObservable(this);
  }

  setId(newValue) {
    this.id = newValue;
  }
}

export default NFT;
