import useTimeout from '../hooks/use-timeout';
import { useEffect, useState } from 'react';

const step = 50;

const extraSteps = [
  [step, false],
  [step*2, true],
  [step*2, false],

  [1000 + step, true],
  [1000 + (step * 2), false],
  [1000 + (step * 3), true],
  [1000 + (step * 4), false],
  
  [2000 + step * 0.75, true],
  [2000 + (step * 0.75 * 2), false],
  [2000 + (step * 0.75 * 3), true],
  [2000 + (step * 0.75 * 4), false],
  [2000 + (step * 0.75 * 5), true],
  [2000 + (step * 0.75 * 6), false],
  [2000 + (step * 0.75 * 6), true],
];

export default function({children, delay=0, flicker, show: _show}) {
  const [show, setShow] = useState(_show);
  const [showClass, setShowClass] = useState('');

  useEffect(() => {
    setShowClass(show ? 'show' : '');
  }, [show]);
  useEffect(() => {
    if (_show) {
      setTimeout(() => {
        setShow(true);
      }, delay);
      if (flicker) {
          extraSteps.forEach(([flick, on]) => {
            setTimeout(() => {
              setShow(on);
            }, flick+delay);
          });
      }
    }
  }, [_show]);


  return show ? <div className={`delayed ${showClass}`}>{children}</div> : <div style={{visibility: 'hidden'}}>{children}</div>
}

