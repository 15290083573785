import { v4 } from 'uuid';
import { makeAutoObservable, observable, computed, action, flow } from "mobx"

class Backend {
  connected = null
  pendingAddress = {};
  network = 'mainnet'

  constructor() {
    makeAutoObservable(this)
    // setTimeout(() => {
    //   if (this.connected === null) {
    //     this.setConnected(false);
    //   }
    // }, 2000);
  }

  setConnected(newValue) {
    this.connected = newValue;
  }

  setPendingAddress(id, address) {
    this.pendingAddress[id] = address;
  }

  setNetwork(newValue) {
    this.network = newValue;
  }
}


export default Backend;
