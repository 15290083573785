import { createTheme } from '@mui/material/styles';
import * as colors from '@mui/material/colors';

const darkTheme = createTheme({
  typography: {
    allVariants: {
      color: colors.lightGreen[50],
    },
    h1: {
      fontSize: {
        xs: '1.5rem',
        sm: '2.5rem',
      }
    },
    h2: {
      fontSize: {
        xs: '1.5rem',
        sm: '2rem',
      }
    }
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#76f935'
    },
    secondary: {
      main: colors.lightGreen[300],
    }
  },
});


export default darkTheme;
