import Backend from './backend';
import Session from './session';
import Account from './account';
import Countdown from './countdown';
import DimStep from './dimstep';
import DiscountToken from './discounttoken';
import Nft from './nft';

export const backend = new Backend();
export const account = new Account();

export const countdown = new Countdown();
export const session = new Session({ persistent: true });

export const dimStep = new DimStep(countdown);
export const discountToken = new DiscountToken({ persistent: true });

export const NFT = new Nft();
