import ReconnectingWebSocket from 'reconnecting-websocket';
import WalletConnectQRCodeModal from "algorand-walletconnect-qrcode-modal";
import { v4 } from 'uuid';
import { countdown } from './state/index';

export const host = 'isitalgorandsbirthday.com';

let urls;

function shortenAddress(addr, n=6) {
  return addr.slice(0, n) + '...' + addr.slice(-n)
}

let _cookieDomain;

if (window.location.hostname.endsWith(host)) { // eslint-disable-line
  const host = `cooo.${window.location.hostname}`;
  urls = [`wss://${host}:443`]; // eslint-disable-line
  _cookieDomain = `.${window.location.hostname}`;
} else {
  const host = window.location.hostname;
  _cookieDomain = host;
  urls = [`ws://${host}:8000`]; // eslint-disable-line
}

export const cookieDomain = _cookieDomain;

let urlIndex = 0;

// round robin url provider
const urlProvider = () => urls[urlIndex++ % urls.length];

class Api { 
  enqueueSnackbar;
  closeSnackbar;
  snackbarKeys = {};
  onConnectCallback;
  connect(backendStore, accountStore, discountTokenStore) {
    if (this.rws) {
      this.backendStore?.setConnected(null);
      this.rws.reconnect();
      return;
    }
    this.backendStore = backendStore;
    this.accountStore = accountStore;
    this.discountTokenStore = discountTokenStore;

    console.log('doc cook', document.cookies);
    this.rws = new ReconnectingWebSocket(urlProvider);
    this.rws.addEventListener('open', () => this.connected());
    this.rws.addEventListener('close', this.disconnected);
    this.rws.addEventListener('message', this.processMessage);
    this.rws.addEventListener('error', console.log);
  }
  connected() {
    this.backendStore.setConnected(true);
    if (this.onConnectCallback)
      this.onConnectCallback();
  }
  onConnect(cb) {
    this.onConnectCallback = cb;
  }
  disconnected = () => {
    console.log("api.disconnected");
    this.backendStore.setConnected(false);
  }
  disconnect() {
    this.rws.removeEventListener('close', this.disconnected);
    this.rws.close();
  }
  queryPromises = {};
  async query(data) {
    if (!data.uuid)
      data.uuid = v4();
    let promiseObject;
    const promise = new Promise((resolve, reject) => promiseObject = { resolve, reject });
    console.log('sent', data);
    this.queryPromises[data.uuid] = promiseObject;
    this.rws.send(JSON.stringify(data));
    return promise;
  }

  subscriptionCallbacks = {};
  subscribe(data, cb) {
    if (!data.sub)
      data.sub = v4();
    this.subscriptionCallbacks[data.sub] = cb;
    this.rws.send(JSON.stringify(data));
  }
  send(obj) {
    this.rws.send(JSON.stringify(obj));
  }
  async connectWallet(secondary) {
    try {
      const response = await this.query({cmd: 'connect-wallet', secondary});
      if (!response.uri) {
        // TODO message something went wrong
        console.warn('expected response.uri, not found in', response);
      }
      WalletConnectQRCodeModal.open(response.uri);
    } catch(e) {
      WalletConnectQRCodeModal.close();
      this.error(e.message);
      // TODO flash message
    }
  }
  enqueue(variant, message, autohide = 4000, key, propsIn) {
    if (this.enqueueSnackbar) {
      if (key)
        if (this.snackbarKeys[key])
          this.closeSnackbar(key);
      const props = autohide < 1 ? { persist: true } : { autoHideDuration: autohide };
      const propsObj = { variant, ...props, ...propsIn };
      console.log(propsObj);
      const k = this.enqueueSnackbar(message, propsObj);
      if (key)
        this.snackbarKeys[key] = k;
    } else {
      alert(message);
    }
  }
  info(...props) {
    return this.enqueue('info', ...props);
  }
  warning(message, autohide = 8000, ...props) {
    return this.enqueue('warning', message, autohide, ...props);
  }
  error(message, autohide = 12000, ...props) {
    this.enqueue('error', message, autohide, ...props);
  }
  closeSnackbar(key) {
    const actualKey = this.snackbarKeys[key];
    if (actualKey)
      this.doCloseSnackbar(actualKey);
  }
  disconnectWallet = (secondary) => {
    this.send({cmd: 'disconnect-wallet', secondary});
  }
  processMessage = (msg) => {
    console.log('received', msg);
    try {
      const data = JSON.parse(msg.data);
      if (data.subscription) {
        const { subscription, error, close, ...subscriptionData } = data;
        const callback = this.subscriptionCallbacks[subscription];
        if (!callback) {
          throw new Error(`Received subscription update for ${subscription} but no such callback registered`);
        }
        if (error) {
          return callback(error);
        }
        if (close)
          delete this.subscriptionCallbacks[subscription];
        if (Object.keys(subscriptionData).length)
          callback(null, subscriptionData);
      } else if (data.responseTo) {
        const prom = this.queryPromises[data.responseTo];
        const { cmd, responseTo, ...response } = data;
        if (response && response.error)
          prom.reject(new Error(response.error));
        else
          prom.resolve(response);
        delete this.queryPromises[data.responseTo];
      } else {
        switch(data.cmd) {
          case 'set-time':
            this.processServerTime(data);
            break;
          case 'set-discount-token':
            if (data.token && data.token !== 'undefined') {
              if (data.token && this.discountTokenStore.token !== data.token) {
                if (!this.discountTokenStore.token)
                  this.info(`Received NFT discount token`);
                this.discountTokenStore.setToken(data.token)
              }
            } else
              this.discountTokenStore.setToken()
            break;
          case 'update-account':
            WalletConnectQRCodeModal.close();
            this.accountStore.setAddress(data.account ?? false);
            if (data.account) this.info(`Connected ${data.account && shortenAddress(data.account)}`, 2000);
            break;
          case 'error': 
            // TODO show error
              const error = data.error || data.message;
              if (error)
                this.error(error);
              break;
          case 'warning': 
            // TODO show warning
              const warning = data.warning || data.message;
              if (warning)
                this.warning(warning);
              break;
          case 'message': 
              if (data.message)
                this.info(data.message, data.autohide);
              break;
          case 'wallet-connect-error':
            WalletConnectQRCodeModal.close();
            this.error(data.error)
          default: 
            console.warn('unknown cmd', data.cmd, data);
        }
      }
    } catch(e) {
      console.log('processMessage error', e);
    }
  }
  async getServerTime() {
    const response = await this.query({ cmd: 'getTime' });
    this.processServerTime(response);
  }
  processServerTime(msg) {
    const { time, bdayStart, bdayEnd } = msg
    const ourTime = Date.now();
    const delta = time - Math.floor(ourTime/1000);
    countdown.setServerDiff(delta);
    countdown.setFrom(bdayStart);
  }
}

async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const a = new Api();

window._zapi = a;

export default a;
