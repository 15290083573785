import { createRef } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from '@mui/material/CssBaseline';
import { SnackbarProvider } from 'notistack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import theme from './theme.js';
import ApiComponent from './components/api.js';
import Home from './routes/Home';
import NFT from './routes/NFT';

import NFT2022 from './routes/NFT2022';
import BuyNFT2022 from './routes/BuyNFT2022';
import ThanksNFT2022 from './routes/ThanksNFT2022';
import HappyBirthday2022 from './routes/HappyBirthday2022';

import NFT2023 from './routes/NFT2023';
import BuyNFT2023 from './routes/BuyNFT2023';
import ThanksNFT2023 from './routes/ThanksNFT2023';
import HappyBirthday2023 from './routes/HappyBirthday2023';

import NFT2024 from './routes/NFT2024';
import BuyNFT2024 from './routes/BuyNFT2024';
import ThanksNFT2024 from './routes/ThanksNFT2024';
import HappyBirthday2024 from './routes/HappyBirthday2024';

import Fourohfour from './routes/Fourohfour';
import Layout from './Layout.jsx';
import { account, backend, session, countdown, dimStep, discountToken, NFT as NFTStore } from './state/index';

function App() {
  const notistackRef = createRef();

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider   
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        ref={notistackRef}
        action={(key) => (
          <IconButton onClick={onClickDismiss(key)}>
            <CloseIcon />
          </IconButton>
        )}
      >
        <ApiComponent session={session} backend={backend} account={account} />
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Home dimStep={dimStep} countdown={countdown} />} />

              <Route path="nft" element={<NFT />} />
              <Route path="nft/2022" element={<NFT2022 NFT={NFTStore} />} />
              <Route path="nft/2022/buy" element={<BuyNFT2022 discountToken={discountToken} account={account} NFT={NFTStore} />} />
              <Route path="nft/2022/thanks" element={<ThanksNFT2022 account={account} NFT={NFTStore} />} />

              <Route path="nft/2023" element={<NFT2023 NFT={NFTStore} />} />
              <Route path="nft/2023/buy" element={<BuyNFT2023 discountToken={discountToken} account={account} NFT={NFTStore} />} />
              <Route path="nft/2023/thanks" element={<ThanksNFT2023 account={account} NFT={NFTStore} />} />

              <Route path="nft/2024" element={<NFT2024 NFT={NFTStore} />} />
              <Route path="nft/2024/buy" element={<BuyNFT2024 discountToken={discountToken} account={account} NFT={NFTStore} />} />
              <Route path="nft/2024/thanks" element={<ThanksNFT2024 account={account} NFT={NFTStore} />} />

              <Route path="*" element={<Fourohfour />} />
            </Route>
            <Route path="happy/birthday/2022" element={<HappyBirthday2022 />} />
            <Route path="happy/birthday/2023" element={<HappyBirthday2023 />} />
            <Route path="happy/birthday/2024" element={<HappyBirthday2024 />} />
          </Routes>
        </BrowserRouter>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
