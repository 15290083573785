import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ConnectButton from '../components/connect-button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import A from '@mui/material/Link';
import NFTVideo from '../components/nft2023';
import { T, P } from '../components/Typography';
import theme from '../theme';
import { observer } from 'mobx-react-lite';
import { NFT2023ID } from '../constants.js';

const D = () => <Divider sx={{ m: 4, mb: 4 }} />;

function NFT({ NFT} ) {
  const navigate = useNavigate();
  const location = useLocation();

  const goBuy = useCallback(() => {
    navigate('/nft/2023/buy');
  }, [navigate]);

  const viewInNFTExplorer = useCallback(() => {
    const url = `https://explorer.perawallet.app/asset/${NFT2023ID}`;
    if (!window.open(url))
      location.href = url;
  }, [NFT2023ID]);

  return <Box>
    <Typography align="center" variant="h1" color="primary" sx={{ mb: 3 }}>2023 Algorand Birthday NFT</Typography>
    <Box sx={{overflow: 'hidden', maxWidth: '95vw' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3, overflow: 'hidden' }}> 
        <NFTVideo />
      </Box>
    </Box>
    <T>The NFT we minted to celebrate Algorand MainNet's 4 years of fast & stable operation since Block Zero is based on the 3D Algorand logo that you may remember from our  <A component={Link} to="/happy/birthday/2023">fourth MainNet anniversary</A> celebration animation.</T>
    <T>It is a fractional NFT with up to <P>208 copies</P> available for purchase during June 11, 2023. 8 of these are reserved and the other 200 are available to be claimed. We picked 208 for the number of calendar weeks elapsed between June 11, 2019 and June 11, 2023. </T>
    <T>It is minted with the special occasion <P>JUNEII..CAME</P> address to mark the occasion. All future NFTs will be minted with the same address.</T>
    <T>There are <P>no</P> freeze or clawback capabilities on this NFT. Sales are final.</T>
    <D />
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'center' }}>
        <Button onClick={goBuy} variant="contained">Check Availability</Button>
        <Box sx={{ width: 10, height: 10}} >&nbsp;</Box>
        <Button onClick={viewInNFTExplorer} variant="outlined">View on Pera Explorer</Button>
      </Box>
    </Box>
    <D />
    <T>The NFT is recognized as a collectible on the Pera NFT Wallet and the video displays properly on both Android and iOS. The encoding used is common enough to be supported by Pera, as well as future NFT wallets, for years to come. The video file is stored immutably on IPFS.</T>
    <T>If you were present on the website during June 11, 2023, 00:00 UTC, your browser received a code that makes you eligible for a free copy of the NFT. This will be applied automatically.</T>
  </Box>;
}

export default observer(NFT);
