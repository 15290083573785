import { useEffect } from 'react';
import { useLocation, Outlet } from "react-router-dom";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { observer } from 'mobx-react-lite';
import Header from './components/Header';
import Footer from './components/Footer';
import { countdown } from './state/index';
import moment from 'moment';
import { dimStep } from './state/index';

const Padding = () => <Box sx={{ mb: 8 }} />;

export default function () {
  const location = useLocation();

  useEffect(() => {
    if ( location?.hash === '#fakedown' ||
      location?.hash === '#fakedown1d' ||
        location?.hash === '#fakebday') {
      const now = moment();
      if (location.hash === '#fakedown') {
        now.add(15, 'seconds');
      } else if (location.hash === '#fakedown1d') {
        now.add(3, 'hour');
        now.add(15, 'seconds');
      } else {
        now.subtract(12, 'seconds');
      }
      countdown.setFrom(now);
      countdown.setProper(true);
      countdown.disableSetFrom(true);
    }
  }, [location?.hash]);

  return (<>
      <Header dimStep={dimStep} />
    <Container sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', maxWidth: { xl: 0.6, lg: 0.8, md: 1.0, sm: 1, xs: 1 }, pt: 4, pb: 4 }}>
        <Outlet />
      </Container>
      <Footer dimStep={dimStep} />
    </>
  );
};
