import { forwardRef, useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import api, { host } from '../api';
import useUnload from '../hooks/use-unload';
import { useSnackbar } from 'notistack';
import { CookiesProvider, useCookies } from 'react-cookie';
import { discountToken } from '../state/index.js';

function TheApi({backend, account, session}) {
  const [cookies, setCookie, removeCookie] = useCookies(['uuidd']);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [backendHasConnected, setBackendHasConnected] = useState(false);
  const [backendErrorSnackbar, setBackendErrorSnackbar] = useState();

  api.enqueueSnackbar = enqueueSnackbar;
  api.doCloseSnackbar = closeSnackbar;

  useEffect(() => {
    return () => console.log('api component unmounted');
  }, []);

  useEffect(() => {
    if (backend.connected) {
      setBackendHasConnected(true);
      if (backendErrorSnackbar) {
        closeSnackbar(backendErrorSnackbar);
        enqueueSnackbar('Backend reconnected', { variant: 'info', autoHideDuration: 2000 });
      }
    } else if (!backend.connected && backendHasConnected) {
      setBackendErrorSnackbar(enqueueSnackbar('Backend disconnected', { variant: 'error', persist: true }));
    }
  }, [backend.connected]);

  useEffect(() => {
    if (cookies.uuidd) {
      session.setSession(cookies.uuidd);
    } else {
      const isLocal = window.location.protocol === 'http:';
      const opts = isLocal ? { path: '/' } : { path: '/', domain: host, secure: true };
      setCookie('uuidd', session.generate(), opts);
    }
    api.connect(backend, account, discountToken);
  }, [cookies]);

  useUnload(e => {
    setBackendHasConnected(false);
    api.disconnect()
  });

  useEffect(() => {
    api.onConnect(() => {
      api.getServerTime();
    });
  }, []);

  return (<>
    </>
  );
};

export default observer(TheApi);
