import { useState, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { HFlex, VFlex } from '../components/layout';
import Tooltip from '@mui/material/Tooltip';

function Label({children, ...props}) {
  return <Typography {...props} color="primary">{children}</Typography>
}

function Value({children, ...props}) {
  return <Typography {...props} sx={{ fontSize: '2rem' }} color="primary">{children}</Typography>
}

function Blah({children}) {
  return <VFlex sx={{m: { xs: 1, sm: 2 }}}>{children}</VFlex>
}

const BirthdayCountdown = observer(({ countdown, showTitle, showLabel }) => {
  const { proper, diff, seconds, minutes, hours, days, months, ordinal: nthBirthday } = countdown;

  const labelClass = useMemo(() => {
    return showLabel ? {} : { classes: { root: "fg-fade" } };
  }, [showLabel]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    if (countdown.diff >= 0 && !show)
      setShow(true);
  }, [countdown.diff]);

  return <>
    { show && <><Typography classes={{ root: showTitle ? 'none' : 'fg-fade' }} variant="h2" color="primary">{nthBirthday} Birthday Countdown</Typography>
    { countdown.from ? <HFlex>
          { months ?
            <Blah>
              <Label>Months</Label>
              <Value>{months}</Value>
            </Blah> : null
          }
          { days ?
            <Blah>
              <Label {...labelClass}>Days</Label>
              <Value>{days}</Value>
            </Blah> : null
          }
          <Blah>
            <Label {...labelClass}>Hours</Label>
            <Value {...labelClass}>{hours}</Value>
          </Blah>
          <Blah>
            <Label {...labelClass}>Minutes</Label>
            <Value {...labelClass}>{minutes}</Value>
          </Blah>
          <Blah>
            <Label {...labelClass}>Seconds</Label>
            <Value {...labelClass}>{seconds}</Value>
          </Blah>
    </HFlex> : <Box sx={{mb: 2, pt: '50px', pb: '50px', width: 100, }}><div><LinearProgress /></div></Box> }
    { !proper ? <Tooltip title="Loading server time"><LinearProgress sx={{ opacity: 0.5, width: 50 }}/></Tooltip> : <LinearProgress sx={{width: 50, visibility: 'hidden'}} /> } </> }
  </>
});

export default BirthdayCountdown;
