import { useCallback } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import ConnectButton from '../components/connect-button';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom';
import A from '@mui/material/Link';
import NFTVideo from '../components/nft2024';
import { T, P } from '../components/Typography';
import theme from '../theme';
import { observer } from 'mobx-react-lite';
import { NFT2024ID } from '../constants.js';

const D = () => <Divider sx={{ m: 4, mb: 4 }} />;

function NFT({ NFT} ) {
  const navigate = useNavigate();
  const location = useLocation();

  const goBuy = useCallback(() => {
    navigate('/nft/2024/buy');
  }, [navigate]);

  const viewInNFTExplorer = useCallback(() => {
    const url = `https://explorer.perawallet.app/asset/${NFT2024ID}`;
    if (!window.open(url))
      location.href = url;
  }, [NFT2024ID]);

  return <Box>
    <Typography align="center" variant="h1" color="primary" sx={{ mb: 3 }}>2024 Algorand Birthday NFT</Typography>
    <Box sx={{overflow: 'hidden', maxWidth: '95vw' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 3, overflow: 'hidden' }}> 
        <NFTVideo />
      </Box>
    </Box>
    <T>This NFT celebrates Algorand MainNet's <P>5 years </P>of fast & stable operation since Block Zero. The V shape is a reference to the roman numeral <P>V for 5</P>. One side reads "2019 / 2024", and the other "ALGO / V ANNI".</T>
    <T>It is a fractional NFT with up to <P>260 copies</P> available for purchase during June 11, 2024. 10 of these are reserved and the other 250 are available to be claimed. We picked 260 for the number of calendar weeks elapsed between June 11, 2019 and June 11, 2024. Any NFTs not claimed or sold on June 11th, 2024 will be burned.</T>
    <T><P>50%</P> of ALGO proceeds will be <P>donated to the EFF</P>, and 50% of COOP proceeds will be donated to the COOP DAO CRV</T>
    <T>It is minted with the special occasion <P>JUNEII..CAME</P> address to mark the occasion. All future NFTs will be minted with the same address.</T>
    <T>There are <P>no</P> freeze or clawback capabilities on this NFT. Sales are final.</T>
    <D />
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row' }, justifyContent: 'center' }}>
        <Button onClick={goBuy} variant="contained">Check Availability</Button>
        <Box sx={{ width: 10, height: 10}} >&nbsp;</Box>
        <Button onClick={viewInNFTExplorer} variant="outlined">View on Pera Explorer</Button>
      </Box>
    </Box>
    <D />
    <T>The NFT is recognized as a collectible on the Pera NFT Wallet and the video displays properly on both Android and iOS. The encoding used is common enough to be supported by Pera, as well as future NFT wallets, for years to come. The video file is stored immutably on IPFS.</T>
    <T>If you were present on the website during June 11, 2024, 00:00 UTC, your browser received a code that makes you eligible for a free copy of the NFT. This will be applied automatically.</T>
  </Box>;
}

export default observer(NFT);
