import Box from '@mui/material/Box';

const Video = ({width = 600, height = 600}) => <video preload="auto" width={width} height={height} muted={true} loop={true} playsInline={true}>
  <source src="/spin.webm" type="video/webm" />
  <source src="/spin.mov" type="video/quicktime" />
  <source src="/spin.mp4" type="video/mp4" />
</video>;

const Container = ({start}) => <>
  <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none', }}}>
    <Video start={start} width={300} height={300} />
  </Box>
  <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' }}}>
    <Video start={start} width={450} height={450} />
  </Box>
  <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
    <Video start={start} width={600} height={600} />
  </Box>
</>;

export default Container;
