import { v4 } from 'uuid';
import { makeAutoObservable } from "mobx"

class Account {
  address = null
  rekeyTo = null
  balance = null

  constructor(newObj) {
    makeAutoObservable(this);
    const { address, rekeyTo, balance } = newObj ?? {};
    this.address = address ?? null;
    this.rekeyTo = rekeyTo;
    this.balance = balance;
  }

  setAddress(address) {
    this.address = address;
  }

  setBalance(balance) {
    this.balance = balance;
  }
}

export default Account;
