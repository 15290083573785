import { v4 } from 'uuid';
import { makeAutoObservable, observe, observable, computed, action, flow } from "mobx"

class DimStep {
  step = Infinity;
  birthday = false

  constructor(countdown) {
    makeAutoObservable(this)
    this.countdown = countdown;
    this.diffStep();
    observe(countdown, 'diff', this.diffStep);
    this.breaks = {
      header: 6,
      h2: 8,
      countdown: 4,
      countdownLabel: 1,
      h3: 8,
      footer: 6,
    };
  }

  diffStep = () => {
    const { diff } = this.countdown;
    if (diff > 1) {
      this.enabled = true;
    }
    if (this.enabled && diff < 8000 && diff >= 0) {
      this.step = Math.floor(diff/1000);
      if (this.enabled && diff >= 6000 && diff < 7500) {
        document.body.classList.add('bg-fade');
        setTimeout(() => document.body.classList.remove('bg-fade'), 15000);
      }
    }

    if (diff < 1 && diff > -86400000 && !this.birthday)
      this.birthday = true;

    if (diff < -86400000 && this.birthday) {
      console.log('birthday passed');
      this.birthday = false
    }
    
    if (diff < 86400000 && !this.enabled) {
      this.enabled = true;
    }

    if (diff < -2000 && diff > -86400000 && this.enabled) {
      this.enabled = false;
      this.step = Infinity;
    }
  }
}

export default DimStep; 
