import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import api from '../api';
import LinearProgress from '@mui/material/LinearProgress';
import shorten from '../utils/shorten-address';

const ConnectButton = ({account, targetAddress, disabled, onDisconnect, title, sx}) => {
  const [short, setShort] = React.useState();
  const defaultLabel = 'Wallet';
  const [label, setLabel] = React.useState(defaultLabel);

  React.useEffect(() => {
    if (targetAddress)
      setLabel(shorten(targetAddress));
    else
      setLabel(defaultLabel);
  }, [targetAddress]);

  const connecting = account.address === null;
  const connected = !!account.address;

  const click = React.useCallback(() => {
    if (account.address) {
      api.disconnectWallet();
      if (onDisconnect)
        onDisconnect();
    } else {
      api.connectWallet();
    }
  }, [account?.address, account?.setAddress]);

  React.useEffect(() => {
    const addr = account?.address;
    if (!addr)
      setShort();
    else
      setShort(shorten(addr));
  }, [account?.address]);

  if (connected) return (<Box sx={{display: 'flex', flexDirection: { xs: 'column', md: 'row' }}}>
    <Button color="success" variant="outlined">Connected: {short}</Button>
    <Box sx={{ width: 10, height: 10}} />
    <Button color="error" variant="outlined" onClick={click}>Disconnect</Button>
  </Box>);
  if (connecting) return (<Button disabled sx={sx}><Box sx={{width: 100}}><LinearProgress /></Box>&nbsp;</Button>);
  return (<Button variant="contained" disabled={disabled} sx={sx} onClick={click}>{ title || `Connect ${label}`}</Button>);
};

export default observer(ConnectButton);
