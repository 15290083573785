import { useMemo, useState, useEffect, useCallback } from 'react';
import { countdown } from '../state/index';
import { AlgorandLogo } from '../components/AlgorandLogo';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useInterval from 'react-useinterval';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import BirthdayCountdown from '../components/countdown';
import { HFlex, VFlex } from '../components/layout';
import { observer } from 'mobx-react-lite';
import PreloadVideo from '../components/preloadvideo2022';

function Home({ dimStep, countdown }) {
  const [birthdayYear, setBirthdayYear] = useState(2024);
  const [nthBirthday, setNthBirthday] = useState('5th');

  const navigate = useNavigate();

  const gCalurl = useMemo(() => `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Algorand%20MainNet%20${nthBirthday}%20Birthday&dates=${birthdayYear}0611T000000Z/${birthdayYear}0611T235900Z&details=Celebrate%20Algorand%20MainNet's%20${nthBirthday}%20Birthday%20with%20a%20commemorative%20NFT%20from%20IsItAlgorandsBirthday.com!%20Free%20if%20you%20are%20there%20at%20midnight%20UTC.%20And%20free%20fireworks%20for%20everyone%20during%20the%20big%20day!&location=https://isitalgorandsbirthday.com/&trp=true&ctz=utc`, [birthdayYear, nthBirthday]);

  useEffect(() => {
    setNthBirthday(countdown.ordinal);
    setBirthdayYear(countdown.from.year());
  }, [countdown.nth]);

  const addToGoogleCal = useCallback(() => {
    if (!window.open(gCalurl)) {
      navigate(gCalurl);
    }
  }, [navigate, gCalurl]);

  useEffect(() => {
    if (dimStep.birthday) {
      navigate(`/happy/birthday/${birthdayYear}`);//
    }
	}, [dimStep.birthday, birthdayYear]);

  return <VFlex sx={{ flexGrow: 1}}>

    <VFlex>
      <Typography align="center" classes={{ root: dimStep.step > dimStep.breaks.h2 ? 'none' : 'fg-fade' }}>
        Algorand MainNet launched on June 11th 2019 at 00:00 UTC.<br />
      </Typography>
      { /* <Typography classes={{ root: 'huge' }}>Downtime</Typography> */ }
    </VFlex>

    <VFlex sx={{ mt: 5, flexGrow: 1 }}>
      <BirthdayCountdown countdown={countdown} showLabel={dimStep.step > dimStep.breaks.countdownLabel} showTitle={dimStep.step > dimStep.breaks.countdown} />
    </VFlex>

    <VFlex className={dimStep.step > dimStep.breaks.h3 ? 'none' : 'fg-fade' }>
      <Typography sx={{mb: 3}} align="center">
        Free NFT drop on June 11 at midnight UTC exactly.
      </Typography>
      <Button variant="outlined" onClick={addToGoogleCal}>Add to Google Calendar</Button>
    </VFlex>

    <PreloadVideo />
  </VFlex>
}

export default observer(Home);
