import { v4 } from 'uuid';
import { makeAutoObservable } from "mobx"

class Session {
  _type = 'session';
  id = null

  constructor({ persistent }) {
    makeAutoObservable(this);
  }

  generate() {
    this.id = v4();
    return this.id;
  }

  setSession(newValue) {
    this.id = newValue;
  }
}

export default Session;
